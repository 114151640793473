import barba from '@barba/core';

export default class {
    constructor() {
        // `beforeEnter`フックでもinit関数を呼び出すように修正
        barba.hooks.once((data) => {
            this.init();
            //console.log('once')
        });
        barba.hooks.beforeEnter	((data) => {
            this.init();
            //console.log('ページ遷移')
        });
    }

    init() {
        const options = {
            root: null, // Use the viewport as the root
            rootMargin: '0px',
            threshold: 0.5 // Percentage of the element that needs to be visible to trigger the callback
        };

        // Callback function when the element enters or exits the viewport
        function handleIntersection(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // document.getElementById('l-header').classList.add('hero--inview');
                    // document.getElementById('js-drawer').classList.add('hero--inview');
                    // document.getElementById('js-hamburger').classList.add('hero--inview');
                    document.getElementById('l-header').classList.add('hero-inview');
                    document.getElementById('js-drawer').classList.add('hero-inview');
                    document.getElementById('js-hamburger').classList.add('hero-inview');

                } else {
                    // document.getElementById('l-header').classList.remove('hero--inview');
                    document.getElementById('l-header').classList.remove('hero-inview');
                    document.getElementById('js-drawer').classList.remove('hero-inview');
                    document.getElementById('js-hamburger').classList.remove('hero-inview');
                }
            });
        }

        const observer = new IntersectionObserver(handleIntersection, options);
        const heroWrapper = document.querySelector('.index-top');
        observer.observe(heroWrapper);

        
    }
}