
import barba from '@barba/core';
import Swiper from 'swiper';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            this.recommendSlider()
            this.indexShopSlider()
            this.shopSlider()
        })
        barba.hooks.after((data) => {
            this.heroSlider()
            this.recommendSlider()
            this.indexShopSlider()
            this.shopSlider()
        });
        
    }
    
    heroSlider(){
        new Swiper ('.js-hero-slider',{
            loop: true,
            speed: 600,
            easing:"linear",
            slidesPerView: '1',
            centeredSlides: true,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            pagination: {
                el: ".js-hero-slider .swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: '1.8',
                    spaceBetween: 120,
                },
                992: {
                    slidesPerView: '2',
                    spaceBetween: 120,
                }
            }
        })
    }

    recommendSlider() {
        new Swiper ('.js-recommend-slider',{
            spaceBetween: 30,
            effect: 'fade',
            speed: 2000,
            loop: true,
            autoplay: {
                delay: 8000,
                disableOnInteraction: false // 矢印をクリックしても自動再生を止めない
            },
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: '.js-recommend-slider .swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.js-recommend-slider .swiper-button-next',
                prevEl: '.js-recommend-slider .swiper-button-prev',
            },
        })
        // new Swiper ('.js-recommend-slider',{
        //     loop: true,
        //     effect :'fade',
        //     speed: 2000,
        //     allowTouchMove :false,
        //     simulateTouch:false,
        //     autoplay: {
        //         delay: 8000
        //     },
        //     fadeEffect: {
        //         crossFade: true
        //     },
        //     pagination: {
        //         el: '.js-recommend-slider .swiper-pagination',
        //         clickable: true,
        //     },
        //     navigation: {
        //         nextEl: '.js-recommend-slider .swiper-button-next',
        //         prevEl: '.js-recommend-slider .swiper-button-prev',
        //     },
        // })
    }

    indexShopSlider() {
        new Swiper ('.js-store-slider',{
            loop: true,
            effect :'fade',
            speed:3000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 4000
            }
        })
    }


    shopSlider() {
        new Swiper ('.js-shop-slider',{
            effect: 'fade',
            loop: true,
            speed:3000,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false // 矢印をクリックしても自動再生を止めない
            },
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
    }
    
}